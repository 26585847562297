import React from 'react'
import PropTypes from 'prop-types'
import ConfirmLayout from 'src/templates/confirm'
import PageHead from 'src/components/head/PageHead'

// Styles & Images
import RampIcon from 'static/confirm/images/ramp--icon.svg'

// Main component:
const FiatOnrampRedirectPage = ({ location }) => {
  const deepLinkPath = `fiatOnramp${location.search}`
  return (
    <ConfirmLayout
      icon={RampIcon}
      iconSize="largest"
      fullLogo
      page="fiat-onramp"
      deepLinkPath={deepLinkPath}
      location={location}
      backgroundGradient
    />
  )
}

// Main component props:
FiatOnrampRedirectPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

FiatOnrampRedirectPage.defaultProps = {}

export default FiatOnrampRedirectPage

// <head> component:
export function Head() {
  return <PageHead page="fiat-onramp" noindex />
}
